<section class="section map-area pt_180" id="contact">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize custom">찾아오시는길</h2>
                </div>
                <div class="section-heading text-center">
                    <h3 class="text-capitalize">서울특별시 서초구 서초대로 277, 3층(서초동,  기영빌딩)</h3>
                    <p>지하철 2, 3호선 교대역 10번 출구</p>
                </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.4029801019684!2d127.01132592924486!3d37.49348336280105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca1b44a0c9f57%3A0x287324a5566dda81!2z7KSR7JWZ67KV66y07IKs67KV7J24!5e0!3m2!1sko!2skr!4v1626960003316!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
</section>
