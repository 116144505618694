<section id="home" class="section welcome-area h-100vh d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1>중앙법무사법인</h1>
                    <h3 class="fw-3 mt-2 mt-sm-3">귀 조합의 주택정비 사업이</h3>
                    <h3 class="fw-3 mt-2 mt-sm-3">성공적으로 이루어지기를 기원합니다.</h3>
                    <p class="my-3">중앙법무사법인은 20년 이상의 풍부한 경험과 주택정비 사업에 전문화된 인력 및 체계적 시스템을 갖추고 있습니다.
                        중앙법무사법인은 주택정비 사업의 성공을 위한
                        최적의 파트너임을 자부합니다.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-7 pos-abs-top-right pr-0">
        <div class="hero-wrapper ml-auto d-none d-md-block" style="height: 680px; width: 800px;">
            <!-- Hero Thumb -->

            <!-- SVG Clip -->
            <svg height="0" width="0">
                <defs>
                    <clipPath id="svgPath">
                        <path d="M0,0c0,0,18.2,428.9,283.2,549.5S655.1,598.4,800,680V0H0z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</section>
