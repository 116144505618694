import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-six',
  templateUrl: './footer-six.component.html',
  styleUrls: ['./footer-six.component.scss']
})
export class FooterSixComponent implements OnInit {

  mobile = false;
  constructor() { }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.mobile = true;
    }else{
      // false for not mobile device
      this.mobile = false;
    }
  }

}
