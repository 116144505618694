<section class="section service-area overflow-hidden pt_180" id="fulfillment">
    <div class="shapes-container">
        <div class="shape-2"></div>
    </div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize custom">이행각서</h2>
                </div>
            </div>
        </div>

        <div class="row justify-content-between">
            <div class="col-12 col-lg-8 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h3 class="text-capitalize mb-4">중앙법무사법인은 조합이 위임한 모든 등기사무 및 소송(경매, 비송사건 포함) 관련 업무를 처리함에 있어 다음 각 사항을 이행할 것을 확약합니다.</h3>
                    <!-- Service List -->
                    <ul class="service-list">
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>1. 사건의 대리위임은 서면 구두를 막론하고 대리위임의 효력이 있으며, 위임사건의 진행 또는 착수 중 법률행위에 효력이 미치는 모든 사실은 즉시 조합에 통지하겠습니다.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>2. 위임받은 사건은 신속, 정확하게 진행하겠으며,  사건 내용은 절대 비밀을 유지하겠습니다.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>3. 법원 또는 이해관계인으로부터 수령한 재판상 또는 재판 외 모든 문서는 수령하는 즉시 조합에 전달하겠습니다.</p>
                            </div>
                        </li>
                        <!-- Single Service -->
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>4. 보수료는 법정 요금을 초과하여 청구하는 일이 없도록 하며, 만일 초과하여 받았을 때에는 그 초과액을 반환하겠습니다.</p>
                            </div>
                        </li>
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>5. 위임사건과 관련하여 착오, 오류, 누락, 기타 불미사항이 발생될 때에는 본인의 책임하에 보완함으로써 조합의 업무처리에 지장이 없도록 하겠습니다.</p>
                            </div>
                        </li>
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>6. 위임사건을 처리함에 있어 조합 및 조합의 차주와 관련된 서류를 분실 또는 훼손하였을 경우 본인이 사후조치 및 보완책임을 부담하겠습니다.</p>
                            </div>
                        </li>
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>7. 조합으로부터 위임받은 모든 사건을 처리함에 있어 조합과 조합의 차주에 대하여 손해를 끼쳤을 경우에는 이유 여하를 막론하고 차후의 청구에 의하여 그 손해액을 즉시 변상하겠습니다.</p>
                            </div>
                        </li>
                        <li class="single-service media py-2">
                            <div class="service-text media-body">
                                <p>8. 중앙법무사법인을 대리한 사무원의 조합과 관련된 제반 등기업무 및 이와 관련된 행위의 결과에 대하여는 중앙법무사법인이 모든 책임을 지겠습니다.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="mt-5 col-12 col-lg-4 order-1 order-lg-2">
                <div class="service-thumb mx-auto">
                    <div class="thumb-center text-center">
                        <img src="assets/img/owner.JPG" alt="" style="width: 180px">
                        <div class="py-3 text-center">
                            <p style="font-size: 20px; color:#444444;">중앙법무사법인</p>
                            <p style="font-size: 20px; color:#444444;">대표자 &nbsp;&nbsp; 조성천</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
