import { Component, OnInit } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-feature-one',
  templateUrl: './feature-one.component.html',
  styleUrls: ['./feature-one.component.scss'],
  animations: [
    trigger('slideInRight', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)'
        }),
        animate(
            '1200ms',
            style({
              transform: 'translateX(0)',
              display: 'flex'
            })
        )
      ])
    ])
  ]
})
export class FeatureOneComponent implements OnInit {
  state = 1;
  intervalClick: any;
  constructor() { }

  ngOnInit(): void {
    this.stateClick();
  }

  stateClick(): void {
    if (typeof this.intervalClick !== 'undefined') {
      clearInterval(this.intervalClick);
    }
    this.intervalClick = setInterval(() => {
      if (this.state === 1) {
        this.state = 2;
      } else if (this.state === 2) {
        this.state = 3;
      } else {
        this.state = 1;
      }
    }, 5000);
  }

  stopInterval(): void {
    clearInterval(this.intervalClick);
  }

  startInterval(): void {
    this.intervalClick = setInterval(() => {
      if (this.state === 1) {
        this.state = 2;
      } else if (this.state === 2) {
        this.state = 3;
      } else {
        this.state = 1;
      }
    }, 5000);
  }

}
