<footer class="section inner-footer overlay-dark ptb_50" *ngIf="!mobile">
    <div class="container">
        <div class="row justify-content-center h-100">
            <div class="col-12">
                <!-- Footer Items -->
                <div class="footer-items text-center">
                    <!-- Logo -->
<!--                    <div class="col-6 d-inline-block text-left">-->
<!--                        <h2 class="mt-2 mb-3 text-white font-weight-light">대표번호: 02)545-5549</h2>-->
<!--                        <h3 class="text-white font-weight-light">FAX: 02)591-5517</h3>-->
<!--                    </div>-->
                    <div class="col-6 d-inline-block float-right">
                        <div class="navbar-brand w-100 pb-4">
                            <img class="logo float-right" src="assets/img/logo3.png" alt="">
                        </div>
                        <div class="text-right">
                            <h5 class="mt-1 mb-1 text-white font-weight-light">대표법무사 : 조성천 | 사업자번호 : 214 - 86 - 37395</h5>
                            <h5 class="mt-1 mb-1 text-white font-weight-light">주소 : 서울특별시 서초구 서초대로 277, 301호(서초동, 기영빌딩)</h5>
                            <h5 class="mt-1 mb-1 text-white font-weight-light">우편번호 : 06596 | E-MAIL : jalaw03@naver.com</h5>
                            <h5 class="mt-1 mb-1 text-white font-weight-bold" style="font-size: 20px;">대표번호: 02) 595-5549&nbsp;&nbsp;|&nbsp;&nbsp;FAX: 02) 591-5517</h5>
                            <h5 class="mt-1 mb-1 text-white font-weight-light">Copyright by. 중앙법무사법인</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<footer class="section inner-footer overlay-dark ptb_50" *ngIf="mobile">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Footer Items -->
                <div class="footer-items text-center">
                    <!-- Logo -->
                    <div class="navbar-brand">
                        <img class="logo" src="assets/img/logo3.png" alt="">
                    </div>
                    <p class="mt-1 mb-1 text-white">대표법무사: 조성천 | 사업자번호 : 214 - 86 - 37395</p>
                    <p class="mt-1 mb-1 text-white">주소: 서울특별시 서초구 서초대로 277, 301호(서초동, 기영빌딩)</p>
                    <p class="mt-1 mb-1 text-white">우편번호: 06596 | E-MAIL : jalaw03@naver.com</p>
                    <h3 class="mt-2 mb-3 text-white">대표번호: 02) 595-5549&nbsp;&nbsp;|&nbsp;&nbsp;FAX: 02) 591-5517</h3>
                </div>
            </div>
        </div>
    </div>
</footer>

