<section id="participate" class="section features-area pt_180">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize custom">참여제안서</h2>
                </div>
            </div>
        </div>

        <div class="row d-md-block">
            <div class="col-12">
                <div class="row d-flex justify-content-center features-content border-bottom pb-5">
                    <div class="col-12 col-md-4" style="cursor:pointer;" [ngClass]="{'active': this.state === 1}" (click)="state = 1; stateClick()">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-cube"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-1">법률적 측면에서</h3><h5>도와드릴 사항</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4" style="cursor:pointer;" [ngClass]="{'active': this.state === 2}"  (click)="state = 2; stateClick()">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-envato"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-1">실무적 측면에서</h3><h5>처리해 드릴 사항</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4" style="cursor:pointer;" [ngClass]="{'active': this.state === 3}"  (click)="state = 3; stateClick()">
                        <!-- Media Box -->
                        <div class="media-box media rounded-sm p-1 p-lg-4">
                            <!-- Features Icon -->
                            <div class="features-icon pr-3">
                                <span>
                                    <i class="icofont-pie-chart"></i>
                                </span>
                            </div>
                            <!-- Features Text -->
                            <div class="media-body">
                                <h3 class="mb-1">조합에서 향후</h3><h5>해야 할 등기,</h5><h5>예상되는 소송 및 가처분</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row features-slider pt-md-5" (mouseenter)="stopInterval()" (mouseleave)="startInterval()" (touchstart)="stopInterval()" (touchend)="startInterval()">
            <div class="col-12 py-md-3 slideInLeft slide" *ngIf="state === 1" [@slideInRight]>
                <div class="row align-items-center">
                    <div class="col-12">
                        <!-- About Text -->
                        <div class="about-text">
                            <!-- Headings -->
                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">① 협력업체 선정시 법률 자문</h3>
                                </div>
                                <p class="d-sm-block">주택재개발(재건축)정비사업에 있어서 협력업체의 선정은 매우 중요합니다. </p>
                                <p class="d-sm-block">조합에서 체결하는 협력업체와 계약서를 사전에 철저히 검토하여 조합에 불이익이 발생하지 않도록 하겠습니다.</p>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">② 조합운영 전반에 걸친 업무지원</h3>
                                </div>
                                <p class="d-sm-block">조합 운영 시 절차상, 형식상, 내용상으로 법률적인 하자가 발생하지 않도록 법률적 문제에 대해 충분한 상담과 자문을 제공하겠습니다.</p>
                                <p class="d-sm-block">조합을 상대로 하는 민 · 형사소송 및 행정 사항에 대하여 법률 전문가로서의 역할은 물론, 관련 기관 등과 협의 하여 조합에 불이익이 발생하지 않도록 하겠습니다.</p>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">③ 조합을 위한 법률서비스</h3>
                                </div>
                                <p class="d-sm-block">조합원 개인에게 법률 문제가 발생시 무료 또는 최소의 비용으로 법률 자문 서비스를 제공하겠습니다.</p>
                                <p class="d-sm-block">정비사업이 성공적으로 마무리되는 날까지 조합원들의 만족을 위해 최선을 다하겠습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 py-md-3 slideInLeft slide" *ngIf="state === 2" [@slideInRight]>
                <div class="row align-items-center">
                    <div class="col-12">
                        <!-- About Text -->
                        <div class="about-text">
                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">① 주택재개발(재건축)정비사업 관련 각종 법률자문 및 상담</h3>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">② 각종 소송(명도, 매도), 보전처분(처분금지, 점유이전금지 등)</h3>
                                </div>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">③ 조합원들에 대한 등기부상 제 권리 분석</h3>
                                </div>
                                <p class="d-sm-block">조합원 별로 권리분석 데이터 작성 후 조합에 제출하여 조합에서 이주비 지급과 명도 및 철거 시 참고할 수 있도록 조치하겠습니다.</p>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">④ 총회의결은 조합운영에 있어서 가장 중요한 사항입니다.</h3>
                                </div>
                                <p class="d-sm-block">총회 안건에 대한 사전검토와 진행 과정 중에서의 조언, 총회 종료 후 법률적인 검토 등을 완벽하게 함으로써</p>
                                <p class="d-sm-block">향후 조합원 또는 이해관계인들이 절차상, 내용상의 이유를 주장하며 총회의결을 무효화하는 일이 발생치 않도록 조치하겠습니다.</p>
                            </div>

                            <div class="mb-3">
                                <div class="headings d-flex align-items-center mb-4">
                                    <h3 class="text-capitalize">⑤ 등기비용을 최소화하고 등기업무를 신속, 정확하게 처리하겠습니다.</h3>
                                </div>
                                <p class="d-sm-block">대법원이 인가한 법무사 보수규정을 바탕으로 조합과 최대한 협력하여 합리적인 금액으로 모든 업무를 처리하겠습니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 py-md-3 slide" *ngIf="state === 3" [@slideInRight]>
                <div class="row align-items-center">
                    <div class="col-12 col-lg-6">
                        <!-- About Text -->
                        <div class="col-lg-8 about-text">
                            <!-- Headings -->
                            <div class="headings d-flex align-items-center mb-4">
                                <h3 class="text-capitalize">Ⅰ 조합에서 향후 <br> 해야 할 등기는</h3>
                            </div>
                            <p class="d-sm-block my-3">
                                ① 이주비지급에 따른 근저당권 설정등기 <br>
                                ② 국 · 공유지 소유권 이전 등기<br>
                                ③ 철거 후 건축물 말소등기<br>
                                ④ 토지환지에 따른 토지말소 및 보존등기<br>
                                ⑤ 신축건물에 대한 소유권보존등기<br>
                                ⑥ 도로, 공원 등에 대한 기부채납 등기<br>
                                ⑦ 분담금 미납자에 대한 각종처분제한 등기
                            </p>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6">
                        <!-- Features Thumb -->
                        <div class="col-lg-8 about-text">
                            <!-- Headings -->
                            <div class="headings d-flex align-items-center mb-4">
                                <h3 class="text-capitalize">Ⅱ 조합에서 향후 <br>예상되는 소송 및 가처분</h3>
                            </div>
                            <p class="d-sm-block my-3">
                                ① 주택재개발(재건축)정비사업 반대자에 대한
                                매도청구소송, 처분금지 및 점유이전금지가처분 <br>
                                ② 조합의 지시를 따르지 않거나 조합원 의무사항
                                을 따르지 않는 조합원들에 대한 각종 소송 <br>
                                ③ 철거 불응자에 대한 명도 · 철거소송 및
                                명도단행 가처분<br>
                                ④ 조합에 손실을 끼친 사람들에 대한 손해배상
                                청구소송 등<br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
