<section id="home" class="section welcome-area overlay-dark d-flex align-items-center">
    <div class="container mt-5">
        <div class="row align-items-center mt-2">
            <div class="col-12 col-md-7 col-lg-7">
                <div class="welcome-intro">
                    <div class="custom mb-3">
                        <span class="" style="font-size: 20px;">귀 조합의 </span> <span class="" style="font-size: 30px;">주택정비 사업이</span><br>
                    </div>
                    <div class="custom mt-1">
                        <span class="" style="font-size: 30px;">성공적으로 </span> <span class="" style="font-size: 20px;">이루어지기를 기원합니다</span>
                    </div>
                    <p class="mt-3">중앙법무사법인은 20년 이상의 풍부한 경험과 주택정비 사업에</p>
                    <p class="">전문화된 인력 및 체계적 시스템을 갖추고 있습니다.</p>
                    <p class="mt-3">중앙법무사법인은 주택정비 사업의 성공을 위한 최적의 파트너임을 자부합니다.</p>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-5" [@slideInRight]="animation">
                <div class="welcome-thumb text-center mx-auto">
                    <div class="rectangle">
                        <div class="section1">
                            <div class="text right">
                                <p>인원</p>
                                <p class="orange">법무사 13명</p>
                                <p class="orange">사무원33명</p>
                                <p>(2021년 현재)</p>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="text top">
                                <p>수주실적</p>
                                <p class="orange">187개 조합</p>
                                <p class="orange">총 143,689세대</p>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="text bottom">
                                <p>손해배상 총액</p>
                                <p class="orange">106억원 보험</p>
                                <p class="orange">(공제)가입</p>
                            </div>
                        </div>
                        <div class="section1">
                            <div class="text left">
                                <p>설립일자</p>
                                <p class="orange">1999.8.27.</p>
                                <p>(서울지방법원</p>
                                <p>인가번호 4호)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
