<footer class="section footer-area footer-bg overflow-hidden">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row justify-content-center d-none d-sm-flex py-3">
                <div class="col-12 col-sm-10 col-md-7">
                    <!-- Subscribe Form -->
                    <div class="subscribe-form d-flex align-items-center mx-auto">
                        <input type="email" class="form-control" placeholder="Enter your email">
                        <button type="submit" class="btn btn-bordered">Subscribe</button>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="assets/img/logo_white.png" alt="">
                        </a>
                        <p class="text-white mt-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="#">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a class="google-plus" href="#">
                                <i class="fab fa-google-plus-g"></i>
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                            <a class="vine" href="#">
                                <i class="fab fa-vine"></i>
                                <i class="fab fa-vine"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">Home</a></li>
                            <li class="py-2"><a class="text-white" href="#">About Us</a></li>
                            <li class="py-2"><a class="text-white" href="#">Services</a></li>
                            <li class="py-2"><a class="text-white" href="#">Blog</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">FAQ</a></li>
                            <li class="py-2"><a class="text-white" href="#">Privacy Policy</a></li>
                            <li class="py-2"><a class="text-white" href="#">Support</a></li>
                            <li class="py-2"><a class="text-white" href="#">Terms &amp; Conditions</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Download</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">For Windows</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Linux</a></li>
                            <li class="py-2"><a class="text-white" href="#">For IOS</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Android</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Mac</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Top -->
        <div class="shape-top">
            <svg fill="#f6f9fe" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
                c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
                c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
            </svg>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white">&copy; Copyrights 2021 Appia All rights reserved.</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right text-white">Made with <i class="icofont-heart-alt"></i> By <a class="text-white" href="#">Themeland</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>