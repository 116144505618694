<section id="record" class="section pt_180">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize custom">수주실적</h2>
                </div>
                <div class="text-center">
                    <h3 class="text-capitalize">누적실적</h3>
                    <p style="font-size: 18px;">176개 조합 총 137,266 세대</p>
                </div>
            </div>
        </div>


        <div class="web">
            <div class="row">
                <div class="col-12">
                    <div class="benifits-item p-4">
                        <div class="benifits-text">
                            <h3 class="mb-2">2002 ~ 2009</h3>
                            <table class="table">
                                <thead>
                                <tr>
                                    <td class="name">조합명</td>
                                    <td class="amount text-center">세대수</td>
                                    <td class="status text-center">진행상황</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>서울시 송파구 가락시영아파트 주택재건축정비사업조합</td>
                                    <td class="text-center">9,510</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>서울시 관악구 봉천8구역 주택재개발정비사업조합</td>
                                    <td class="text-center">2,561</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>서울시 관악구 봉천7-2구역 주택재개발정비사업조합</td>
                                    <td class="text-center">2,533</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>서울시 성북구 정릉4구역 주택재개발정비사업조합</td>
                                    <td class="text-center">2,304</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>서울시 중구 신당4구역 주택재개발정비사업조합</td>
                                    <td class="text-center">2,282</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="float-right">
                                <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 84조합</span>
                                <span class="font-weight-light"> (조합원 29,616세대)</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Benifits Item -->
            </div>

            <div class="row">
                <div class="col-12">
                    <div class="benifits-item p-4">
                        <div class="benifits-text">
                            <h3 class="mb-2">2010 ~ 2014</h3>
                            <table class="table">
                                <thead>
                                <tr>
                                    <td class="name">조합명</td>
                                    <td class="amount text-center">세대수</td>
                                    <td class="status text-center">진행상황</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>서울시 서대문구 북아현3 재정비촉진구역주택재개발정비사업조합</td>
                                    <td class="text-center">4,810</td>
                                    <td class="text-center">사업시행인가준비중</td>
                                </tr>
                                <tr>
                                    <td>서울시 종로구 돈의문1구역 도시환경정비사업조합</td>
                                    <td class="text-center">1,766</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>경기도 부천시 소사구 계수.범박구역 주택재개발정비사업조합</td>
                                    <td class="text-center">3,724</td>
                                    <td class="text-center">착공</td>
                                </tr>
                                <tr>
                                    <td>경기도 부천시 원미구 도당1-1구역 주택재개발정비사업조합</td>
                                    <td class="text-center">1,896</td>
                                    <td class="text-center">사업시행인가준비중</td>
                                </tr>
                                <tr>
                                    <td>경기도 의왕시 내손다구역 주택재개발정비사업조합</td>
                                    <td class="text-center">3,170</td>
                                    <td class="text-center">이주중</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="float-right">
                                <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 78조합</span>
                                <span class="font-weight-light"> (조합원 43,133세대)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- Benifits Item -->
                <div class="col-12">
                    <div class="benifits-item p-4">
                        <div class="benifits-text">
                            <h3 class="mb-2">2015 ~ 2021</h3>
                            <table class="table">
                                <thead>
                                <tr>
                                    <td class="name">조합명</td>
                                    <td class="amount text-center">세대수</td>
                                    <td class="status text-center">진행상황</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>서울시 동대문구 이문3 재정비촉진구역 주택재개발정비사업조합</td>
                                    <td class="text-center">4,140</td>
                                    <td class="text-center">착공</td>
                                </tr>
                                <tr>
                                    <td>서울시 동대문구 휘경3 재정비촉진구역 주택재개발정비사업조합</td>
                                    <td class="text-center">2,702</td>
                                    <td class="text-center">착공</td>
                                </tr>
                                <tr>
                                    <td>서울시 성동구 왕십리뉴타운1구역 재개발정비사업조합</td>
                                    <td class="text-center">1,971</td>
                                    <td class="text-center">보존등기완료</td>
                                </tr>
                                <tr>
                                    <td>서울시 송파구 장미아파트1,2,3차 주택재건축정비사업조합</td>
                                    <td class="text-center">1,800</td>
                                    <td class="text-center">사업시행인가준비중</td>
                                </tr>
                                <tr>
                                    <td>서울시 용산구 한남2재정비촉진구역 주택재개발정비사업조합</td>
                                    <td class="text-center">1,537</td>
                                    <td class="text-center">사업시행인가준비중</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="float-right">
                                <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 100조합</span>
                                <span class="font-weight-light"> (조합원 61,266세대)</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mobile">
            <div class="discover-text pt-4 pt-lg-0">
                <div class="row">
                    <div class="col-12 col-md-6 py-5">
                        <!-- Icon Box -->
                        <h3 class="mb-2 text-center">2002 ~ 2009</h3>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 송파구 가락시영아파트 주택재건축정비사업조합</span>
                                <p class="">9,510세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 관악구 봉천8구역 주택재개발정비사업조합</span>
                                <p class="">2,561세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 관악구 봉천7-2구역 주택재개발정비사업조합</span>
                                <p class="">2,533세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 성북구 정릉4구역 주택재개발정비사업조합</span>
                                <p class="">2,304세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 중구 신당4구역 주택재개발정비사업조합</span>
                                <p class="">2,282세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="float-right">
                            <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 84조합</span>
                            <span class="font-weight-light"> (조합원 29,616세대)</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 py-5">
                        <!-- Icon Box -->
                        <h3 class="mb-2 text-center">2010 ~ 2014</h3>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 서대문구 북아현3 재정비촉진구역주택재개발정비사업조합</span>
                                <p class="">4,810세대 / 사업시행인가준비중</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 종로구 돈의문1구역 도시환경정비사업조합</span>
                                <p class="">1,766세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">경기도 부천시 소사구 계수.범박구역 주택재개발정비사업조합</span>
                                <p class="">3,724세대 / 착공</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">경기도 부천시 원미구 도당1-1구역 주택재개발정비사업조합</span>
                                <p class="">1,896세대 / 사업시행인가준비중</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <span class="mb-2">경기도 의왕시 내손다구역 주택재개발정비사업조합</span>
                                <p class="">3,170세대 / 이주중</p>
                            </div>
                        </div>
                        <div class="float-right">
                            <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 78조합</span>
                            <span class="font-weight-light"> (조합원 43,133세대)</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6 py-5">
                        <!-- Icon Box -->
                        <h3 class="mb-2 text-center">2015 ~ 2021</h3>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="align-self-center align-self-md-start">
                                <span class="mb-2">서울시 동대문구 이문3 재정비촉진구역 주택재개발정비사업조합</span>
                                <p class="">4,140세대 / 착공</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 동대문구 휘경3 재정비촉진구역 주택재개발정비사업조합</span>
                                <p class="">2,702세대 / 착공</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 성동구 왕십리뉴타운1구역 재개발정비사업조합</span>
                                <p class="">1,971세대 / 보존등기완료</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 송파구 장미아파트1,2,3차 주택재건축정비사업조합</span>
                                <p class="">1,800세대 / 사업시행인가중비중</p>
                            </div>
                        </div>
                        <div class="icon-box media icon-1 px-1 py-2 py-md-4">
                            <!-- Icon Text -->
                            <div class="media-body align-self-center align-self-md-start">
                                <span class="mb-2">서울시 용산구 한남2재정비촉진구역 주택재개발정비사업조합</span>
                                <p class="">1,537세대 / 사업시행인가중비중</p>
                            </div>
                        </div>
                        <div class="float-right">
                            <span class="font-weight-bold" style="font-size: 16px; color:#444444;">외 총 100조합</span>
                            <span class="font-weight-light"> (조합원 61,266세대)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
