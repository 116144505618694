import {Component, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-hero-six',
  templateUrl: './hero-six.component.html',
  styleUrls: ['./hero-six.component.scss'],
  animations: [
    trigger('slideInRight', [
      transition('false => true', [
        style({
          transform: 'translateX(100%)'
        }),
        animate(
            '800ms',
            style({
              transform: 'translateX(0)',
              display: 'flex'
            })
        )
      ])
    ])
  ]
})
export class HeroSixComponent implements OnInit {

  animation = false;
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.animation = true;
    }, 100);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight;
    const max = document.documentElement.scrollHeight;
    if (pos === max )   {
      this.animation = true;
    } else {
      this.animation = false;
    }
  }

}
