<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <div class="navbar-brand">
            <img class="navbar-brand-sticky logo" src="assets/img/logo1.png" alt="sticky brand-logo">
            <img class="navbar-brand-regular logo" src="assets/img/logo2.png" alt="brand-logo">
        </div>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#home"  data-toggle="navbarToggler2">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#introduce"  data-toggle="navbarToggler2">법인소개</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#participate"  data-toggle="navbarToggler2">참여제안서</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#record"  data-toggle="navbarToggler2">수주실적</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#fulfillment"  data-toggle="navbarToggler2">이행각서</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contact"  data-toggle="navbarToggler2">찾아오시는 길</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
