<section class="section pt_180" id="introduce">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize custom">법인소개</h2>
                    <p class="d-sm-block mt-4 color-primary">중앙법무사법인은</p>
                    <p class="d-sm-block">풍부한 경험과 노하우를 바탕으로</p>
                    <p class="d-sm-block">끝까지 책임을 다하겠습니다.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <i class="icofont-user-suited icon"></i>
                                            <button class="btn show" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                풍부한 경험과 노하우
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            중앙법무사법인은 1999. 8. 27 설립된 이래 현재까지 <span class="color-primary">총 187개 조합(총 143,689 세대)</span>의 정비사업 관련 법무 및 등기업무를 위임 받아 성실히 수행하고 있습니다.
                                            중앙법무사법인의 연간 평균 등기업무처리 건수는 약 <span class="color-primary">20,000건으로</span> 업계에서 경험과 능력을 인정받고 있습니다.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <i class="icofont-ui-settings icon"></i>
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                                                전문화된 시스템
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            중앙법무사법인은 엄격한 자격요건 하에 법원의 인가를 받아 설립한 공신력이 높은 법무사법인입니다.
                                            <span class="color-primary">13명의 법무사 외 다수의 직원과 고문으로 구성되어 민사, 형사, 등기, 비송사건 </span> 등 복잡하고 다양한 업무를 체계적으로 수행할 수 있는 시스템을 갖추고 있습니다.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <i class="icofont-bag-alt icon"></i>
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                종합적 법률 서비스 제공
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            중앙법무사법인은 오랜 경험과 지식을 바탕으로 조합에서 필요한 <span class="color-primary">법률자문</span>은 물론, <span class="color-primary">행정업무, 소송업무</span> 등을 <span class="color-primary">무료</span> 또는 <span class="color-primary">소정의 비용</span>으로 지원할 수 있는 전문적인 인력을 보유하고 있습니다.
                                            중앙법무사법인은 설립 이후 수많은 정비 사업을 성공적으로 수행한 실적이 증명하는 것처럼 정비사업 분야에서 업계 최고 수준의 서비스를 제공합니다.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <i class="icofont-thumbs-up icon"></i>
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                손해 발생시 철저한 책임보장
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            중앙법무사법인은 업무처리 중 조합에 손해를 발생시켰을 경우, <span class="color-primary">손해배상금 전액을 보상</span>할 것을 약속합니다.
                                            중앙법무사법인은 법원의 인가를 받아 설립된 법무사 법인으로서 조합에서 위임하신 업무에 대하여 <span class="color-primary">법무사 전원이 무한책임</span>을 지도록 법으로 정해져 있어서 그만큼 신뢰할 수 있습니다.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
<!--                                <div class="card my-2">-->
<!--                                    &lt;!&ndash; Card Header &ndash;&gt;-->
<!--                                    <div class="card-header">-->
<!--                                        <h2 class="mb-0">-->
<!--                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseFive">-->
<!--                                                How can I get product update?-->
<!--                                            </button>-->
<!--                                        </h2>-->
<!--                                    </div>-->
<!--                                    <div id="collapseFive" class="collapse show" data-parent="#appia-accordion">-->
<!--                                        &lt;!&ndash; Card Body &ndash;&gt;-->
<!--                                        <div class="card-body">-->
<!--                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                &lt;!&ndash; Single Accordion Item &ndash;&gt;-->
<!--                                <div class="card my-2">-->
<!--                                    &lt;!&ndash; Card Header &ndash;&gt;-->
<!--                                    <div class="card-header">-->
<!--                                        <h2 class="mb-0">-->
<!--                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">-->
<!--                                                Is this template support rtl?-->
<!--                                            </button>-->
<!--                                        </h2>-->
<!--                                    </div>-->
<!--                                    <div id="collapseSix" class="collapse" data-parent="#appia-accordion">-->
<!--                                        &lt;!&ndash; Card Body &ndash;&gt;-->
<!--                                        <div class="card-body">-->
<!--                                            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa.-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
