import { Component, OnInit } from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss'],
  animations: [
    trigger('slideInRight', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)'
        }),
        animate(
            '500ms',
            style({
              transform: 'translateX(0)',
              display: 'flex'
            })
        )
      ])
    ])
  ]
})
export class HeaderOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}
}
