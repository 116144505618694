<div class="homepage-1">
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-one></app-header-one>
        <app-hero-six></app-hero-six>
        <app-faq-one></app-faq-one>
        <app-feature-one></app-feature-one>
        <app-promo-two></app-promo-two>
        <app-content-one></app-content-one>
        <app-map></app-map>
<!--        <app-content-two></app-content-two>-->
<!--        <app-pricing-one></app-pricing-one>-->
<!--        <app-review></app-review>-->
<!--        <app-team-one></app-team-one>-->
<!--        <app-cta-one></app-cta-one>-->
<!--        <app-download-one></app-download-one>-->
<!--        <app-contact></app-contact>-->
        <app-footer-six></app-footer-six>
    </div>
</div>
