<section id="features" class="section features-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Why Small Business owner's loves Appia</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="features-slider-wrapper">
                    <!-- Work Slider -->
                    <ul class="features-slider owl-carousel">
                        <li class="slide-item">
                            <img src="assets/img/screenshot_1.png" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_2.png" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_3.png" alt="">
                        </li>
                        <li class="slide-item">
                            <img src="assets/img/screenshot_4.png" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <ul class="features-item">
                    <li>
                        <!-- Icon Box -->
                        <div class="icon-box media icon-1 px-1 py-3 py-md-4">
                            <!-- Icon -->
                            <div class="icon rounded-circle border mr-3">
                                <span><i class="fab fa-git-alt"></i></span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Clean Code</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Icon Box -->
                        <div class="icon-box media icon-2 px-1 py-3 py-md-4">
                            <!-- Icon -->
                            <div class="icon rounded-circle border mr-3">
                                <span><i class="fab fa-steam"></i></span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Responsive Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Icon Box -->
                        <div class="icon-box media icon-3 px-1 py-3 py-md-4">
                            <!-- Icon -->
                            <div class="icon rounded-circle border mr-3">
                                <span><i class="fab fa-snapchat"></i></span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Live Chat</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <!-- Icon Box -->
                        <div class="icon-box media icon-4 px-1 py-3 py-md-4">
                            <!-- Icon -->
                            <div class="icon rounded-circle border mr-3">
                                <span><i class="fab fa-github"></i></span>
                            </div>
                            <!-- Icon Text -->
                            <div class="icon-text media-body align-self-center align-self-md-start">
                                <h3 class="mb-2">Easy to Customize</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos.</p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>